import axios from "axios";
// import qs from "qs";
// import local from './local'
// import router from '../router/index'
// axios.defaults.baseURL =window.global_config. BASE_URL//请求地址
// axios.defaults.timeout = 5000//超时时间
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    config.headers['xx-app-type']='wx'
    // console.log('config',config)
    return config;
}, function (error) {
        // error.config.headers['token']=local.get('token')
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {

    return response;
}, function (error) {

    return Promise.reject(error);
});
export function post(url, data,responseType) {
    return new Promise((resolve, reject) => {
        return axios
            .post(
                url,
                data,
              responseType
            )
            .then(function (response) {

                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            });
    })
}
// get
// export function get(url, params) {
//     return new Promise((resolve, reject) => {
//         return axios
//             .get(
//                 url,
//                 {
//                   params   
//                 }
               
               
//             )
//             .then(function (response) {
                 
//                 resolve(response.data)
//             })
//             .catch(function (error) {
//                 reject(error)
//             });
//     })
// }

<template>
  <div class="my-map">
    <div class="no">
      <div class="no-hd">
        <ul>
          <li>{{ Math.ceil(sumMoneyData * 27) }}</li>
          <li>{{ Math.ceil(sumMoneyData * 27/5)}}</li>
          <li>{{ Math.ceil(sumMachineData/25)}}</li>
        </ul>
      </div>
      <div class="no-bd">
        <ul>
          <li>{{ users[0].title }}</li>
          <li>{{ users[1].title }}</li>
          <li>{{ users[2].title }}</li>
        </ul>
      </div>
    </div>
    <div class="map">
      <video
        src="../assets/images/bg.d20178c2.mp4"
        autoplay
        class="map-video"
      ></video>
      <!-- <div class="chart"></div>
      <div class="map1"></div>
      <div class="map2"></div>
      <div class="map3"></div> -->
    </div>
    <!-- 电极狗实时订单 -->
    <my-swipper
      style="width: 560px; margin-left: 35px"
      title="实时订单"
      :tableDate="EdoData"
    ></my-swipper>
  </div>
</template>

<script>
import MySwipper from "./MySwipper.vue";
export default {
  props: {
    option: {
      // 地图数据
      type: Object,
      default: () => ({}),
    },
    users: {
      type: Array,
      default: () => [],
    },
    sumMachineData: {
      type: Number,
      default: 0,
    },
    sumOrderData: {
      type: Number,
      default: 0,
    },
    sumMoneyData: {
      type: Number,
      default: 0,
    },
    EdoData:{
      type:Array,
      default:()=>[]
    }
  },
  components: {
    MySwipper,
  },
  data() {
    return {
      listData: {
        end_time: "",
        lenth: 10,
        start_time: "",
      },
      // mapData: [],
    };
  },
  // 组件加载就初始化
};
</script>

<style lang="scss" scoped>
.my-map {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
  .no {
  background: rgba(101, 132, 226, 0.1);
  padding: 0.1875rem;
  border: 1px solid #0095d1;
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  &::before {
    left: -1px;
    top: -1px;
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 2px solid #00ffff;
    border-left: 2px solid #00ffff;
  }
  &::after {
    right: -1px;
    top: -1px;
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 2px solid #00ffff;
    border-right: 2px solid #00ffff;
  }
  .no-hd {
    position: relative;
    border: 1px solid rgba(25, 186, 139, 0.17);
    ul {
      display: flex;
      li {
        position: relative;
        flex: 1;
        text-align: center;
        height: 1rem;
        line-height: 1rem;
        font-size: 0.495rem;
        color: #ffeb7b;
        padding: 0.05rem 0;
        font-family: electronicFont;
        font-weight: bold;
        &:first-child::after {
          content: "";
          position: absolute;
          height: 50%;
          width: 2px;
          background: rgba(255, 255, 255, 0.2);
          right: 0;
          top: 25%;
        }
        &:nth-child(2)::after {
          content: "";
          position: absolute;
          height: 50%;
          width: 2px;
          background: rgba(255, 255, 255, 0.2);
          right: 0;
          top: 25%;
        }
      }
    }
  }
  .no-bd ul {
    display: flex;
    li {
      flex: 1;
      height: 0.5rem;
      line-height: 0.5rem;
      text-align: center;
      font-size: 0.225rem;
      color: rgba(255, 255, 255, 0.7);
      padding-top: 0.125rem;
    }
  }
 
}
}

</style>

<template>
  <div class="box" :style="{ height: height + 'rem' }">
    <h2>
      <span>{{ title }}</span>
      <slot name="control"></slot>
    </h2>
    <div class="box_max" :style="{ height: boxHeiHght + 'rem' }">
      <div class="box-swipper" :style="{ top: tableTop + 'px' }">
        <div class="swipper-item" v-for="(item, index) in tableList" :key="index">
          {{ item.buyer ? item.buyer : "保密" }}-{{
    item.pay_code ? "微信支付" : "支付宝支付"
  }}-{{ item.order_amount }}-{{ item.hotelName }}
        </div>
      </div>
    </div>
    <div class="first_span"></div>
    <div class="list_span"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    tableDate: {
      type: Array,
      default: () => []
    },
    boxHeiHght: {
      type: [Number,String],
      default: 3.5
    },
    height: {
      type: [Number,String],
      default:3.5
    }
  },
  data() {
    return {
      tableTimer: null,
      tableTop: 0,
      tableList: [],
      tableListSize: 0,
      componentTimer: null,
      visibleSize: 14, //容器内可视最大完整行数
      lineHeight: 42, //每行的实际高度（包含margin-top/bottom,border等）
      componentTimerInterval: 3600000, //刷新数据的时间间隔
      tableTimerInterval: 42, //向上滚动 1px 所需要的时间，越小越快，推荐值 100
    };
  },

  watch: {
    tableDate: {
      handler: function () {
        clearInterval(this.componentTimer);
        this.bsGetProductProcess();
        this.componentTimerFun();
      },
      deep: true,
    },
  },
  beforeDestroy() {
    clearInterval(this.componentTimer);
    clearInterval(this.tableTimer);
  },
  methods: {
    //获取列表数据
    bsGetProductProcess() {
      clearInterval(this.tableTimer);
      this.tableTop = 0;
      if (this.tableDate != "") {
        this.tableList = this.tableDate;
        this.tableActionFun();
      }
    },
    tableActionFun() {
      this.tableListSize = this.tableList.length;
      if (this.tableListSize > this.visibleSize) {
        this.tableList = this.tableList.concat(this.tableList);
        this.tableTimerFun();
      }
    },
    tableTimerFun() {
      var count = 0;
      this.tableTimer = setInterval(() => {
        if (count < (this.tableList.length / 2) * this.lineHeight) {
          this.tableTop -= 1;
          count++;
        } else {
          count = 0;
          this.tableTop = 0;
        }
      }, this.tableTimerInterval);
    },
    componentTimerFun() {
      this.componentTimer = setInterval(() => {
        this.bsGetProductProcess();
      }, this.componentTimerInterval);
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  position: relative;
  height: 240px;
  border: 1px solid #0095d1;
  padding: 0.1875rem 0.3125rem;
  margin-bottom: 25px;
  overflow: hidden;

  .first_span {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 10px;
    height: 10px;
    border-top: 2px solid #00ffff;
    border-left: 2px solid #00ffff;
    z-index: 1;
  }

  &::before {
    position: absolute;
    bottom: -1px;
    left: -1px;
    content: "";
    width: 10px;
    height: 10px;
    border-bottom: 2px solid #00ffff;
    border-left: 2px solid #00ffff;
    z-index: 1;
  }

  .list_span {
    position: absolute;
    bottom: -1px;
    right: -1px;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid #00ffff;
    border-right: 2px solid #00ffff;
    z-index: 1;
  }

  &::after {
    position: absolute;
    top: -1px;
    right: -1px;
    content: "";
    width: 10px;
    height: 10px;
    border-top: 2px solid #00ffff;
    border-right: 2px solid #00ffff;
    z-index: 1;
  }

  h2 {
    height: 0.6rem;
    line-height: 0.6rem;
    color: #fff;
    font-size: 0.25rem;
    font-weight: 400;
  }

  .box_max {
    height: 2.8125rem;
    overflow: hidden;
    position: relative;

    .box-swipper {
      width: 100%;
      position: absolute;
      left: 0;

      .swipper-item {
        height: 35px;
        line-height: 35px;
        color: #eee;
        font-size: 0.25rem;
        background: rgba(3, 145, 167, 0.1);
        border: 1px solid rgb(4, 114, 131);
        padding-left: 0.25rem;
        margin-top: 7px;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>